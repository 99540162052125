import React, { Component } from "react";
import Quote from "../../components/quote/quote";
import ProposalRefs from "../../components/proposal_refs/proposal_refs";
import ProviderProfile from "../../components/provider_profile/provider_profile";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./proposal.css";

class Proposal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  render() {
    const rateClass = `proposal__provider__rating star-${this.props.proposal.rating}`;
    const numQuotes = this.props.proposal.quotes?.length ?? 0
    const lastFeedback = numQuotes > 0 ? this.props.proposal.quotes[numQuotes-1].feedback : ""

    return (
      <div className="proposal">
        <div className="proposal__caretaker">
          <span className="proposal__subtitle_style">L'avis du concierge</span>
          <p className="proposal__caretaker__feedback">
            {lastFeedback}
          </p>
          <ProposalRefs proposal={this.props.proposal} />
        </div>

        <div className="proposal__provider">
          <h6>Prestataire {this.props.place}</h6>
          <div className={rateClass}>
            <i className="star"></i>
            <i className="star"></i>
            <i className="star"></i>
            <i className="star"></i>
            <i className="star"></i>
          </div>

          <Button className="white-button" onClick={this.open}>
            Voir le profil
          </Button>

          <Modal
            id="providerModal"
            className="provider-modal"
            show={this.state.showModal}
            onHide={this.close}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h2>Prestataire {this.props.place}</h2>
                <a
                  href={this.props.proposal.cgv}
                  target="_blank"
                  className="blue-button"
                >
                  Voir CGU
                </a>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ProviderProfile proposal={this.props.proposal} />
            </Modal.Body>
          </Modal>
        </div>
        <div className="proposal__quote-list">
          {this.props.proposal.quotes.map((quote) => {
            return (
              <Quote key={quote.id} quote={quote} needId={this.props.needId} />
            );
          })}
        </div>
      </div>
    );
  }
}

export default Proposal;
